import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import { Hero, About, Support, Apartments } from "page_components/home"

const Home = ({ data, location }) => {
  const allApartmentsPromo = [
    ...data.allWpApartmentSkyTrust.nodes,
    ...data.allWpApartmentRezydencjaCentrum.nodes,
  ]
  const smartEstatePromo =
    data.allWp.nodes[0].smartEstate.acfEstateSettings.promotedApartments

  return (
    <Layout
      location={location}
      seo={{
        title: "Nowe mieszkania na sprzedaż od dewelopera",
        description:
          "Trust Investment S.A. to deweloper realizujący projekty w sektorze nieruchomości mieszkaniowych oraz komercyjnych na terenie całej Polski.",
      }}
      isHome
    >
      <Hero />
      <Apartments
        allApartmentsPromo={allApartmentsPromo}
        smartEstatePromo={smartEstatePromo}
      />
      <About />
      <Support rmMargin />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartmentSkyTrust(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          promo
          apartmentNumber
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
        }
      }
    }
    allWpApartmentRezydencjaCentrum(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          investmentBuilding
          promo
          apartmentNumber
          buildingNumber
          area
          floor
          floorSecond
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
        }
      }
    }
    allWp {
      nodes {
        smartEstate {
          acfEstateSettings {
            promotedApartments {
              investment
              apartments {
                view {
                  localFile {
                    publicURL
                  }
                }
                data {
                  number
                  id
                  rooms
                  floor
                  area
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Home
